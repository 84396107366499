.btn {
  min-width: 164px;
  padding: 0 16px;
  height: 40px;
  color: $white;
  font-size: 12px;
  letter-spacing: 1.4px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid $cerulean;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  &.primary {
    background-color: $cerulean;
    border-color: $cerulean;
    &:hover {
      background-color: $prussian-blue;
      border-color: $prussian-blue;
    }
  }
  &.secondary {
    background-color: $purpley-pink;
    border-color: $purpley-pink;
    &:hover {
      background-color: $berry;
      border-color: $berry;
    }
  }
  &.outline {
    background-color: transparent;
    color: $cerulean;
    &.primary {
      color: $cerulean;
    }
    &.secondary {
      color: $purpley-pink;
    }
    &:hover {
      color: $white;
    }
  }
  &:disabled,
  &:disabled:hover,
  &.secondary:disabled,
  &.primary:disabled {
    background-color: $very-light-pink-two;
    border-color: $very-light-pink-two;
    color: $brown-grey;
    cursor: auto;
  }
}
