@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

$cerulean: #007cc8;
$slate: #54667a;
$black: #000000;
$white: #ffffff;
$purpley-pink: #d9399d;
$brown-grey: #8e8e8e;
$anthrazit: #333333;
$ice-blue: #edf1f5;
$bluegrey: #68b7db;
$very-light-pink: #c0c0c0;
$light-grey-blue: #a0aec4;
$very-light-pink-two: #d8d8d8;
$prussian-blue: #004c7b;
$berry: #a51d72;
$rusty-red: #ce1111;
$athens-gray: #fafafb;

body {
  margin: 0;
  background-color: $ice-blue;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  &:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $cerulean;
    border-color: $cerulean transparent $cerulean transparent;
    animation: loading 1.2s infinite;
    transform: translate3D(0, 0, 0);
  }
  &.sm {
    width: 40px;
    height: 40px;
    &:after {
      width: 32px;
      height: 32px;
      margin: 4px;
      border-width: 3px;
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-session {
  padding: 12px;
}

.container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  box-sizing: border-box;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
  box-sizing: border-box;
  &-bottom .widget-footer .Dropdown-menu {
    bottom: 100%;
    top: auto;
  }
}
.col-1,
.col-2,
.col-3 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  box-sizing: border-box;
}

@media (min-width: 1600px) {
  .col-1 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-2 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

table.tooltip {
  margin-bottom: 8px;
  font-size: 11px;
  line-height: 1.33;
  letter-spacing: -0.06px;
  box-sizing: border-box;
  border-collapse: collapse;
  border: none;
  thead {
    background-color: $anthrazit;
    color: $white;
    font-weight: 300;
    td {
      background-color: $anthrazit;
      padding: 7px 2px;
      &:first-child {
        padding: 7px 5px;
      }
      &:last-child {
        padding: 7px 8px;
      }
    }
    tr {
      background-color: $anthrazit;
    }
  }
  tbody {
    font-weight: 600;
    color: $anthrazit;
    tr {
      background: url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAIBAQEBAQIBAQECAgICAgQDAgICAgUEBAMEBgUGBgYFBgYGBwkIBgcJBwYGCAsICQoKCgoKBggLDAsKDAkKCgr/2wBDAQICAgICAgUDAwUKBwYHCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgr/wgARCAABAAMDAREAAhEBAxEB/8QAFAABAAAAAAAAAAAAAAAAAAAACf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAHDP/8QAGxAAAAcBAAAAAAAAAAAAAAAAAAMEBQg3dLT/2gAIAQEAAT8AjdTzdrX9p4//xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oACAECAQE/AH//xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oACAEDAQE/AH//2Q==')
        repeat-x bottom;
      td:first-child span {
        margin-left: -1px;
        padding-left: 6px;
      }
      td:last-child span {
        margin-right: -1px;
        padding-right: 6px;
      }
    }
    span {
      position: relative;
      padding: 0 3px;
      bottom: -4px;
      background-color: $white;
    }
  }
  .tooltip-title {
    font-weight: 300;
    color: $brown-grey;
    text-align: left;
  }
  .plus-minus {
    position: relative;
    color: $brown-grey;
    font-size: 8px;
    font-weight: 400;
    & + span {
      padding-left: 0;
    }
  }
  .bold {
    font-weight: 600;
  }
  .pl-20 {
    padding-left: 20px;
  }
  .pl-50 {
    padding-left: 50px;
  }
  .pr-50 {
    padding-right: 50px;
  }
  .pr-60 {
    padding-right: 60px;
  }
  .pt-10 {
    padding-top: 10px;
  }
}

.pt-20 {
  padding-top: 20px;
}

.pt-40 {
  padding-top: 40px;
}

.hidden {
  display: none;
}

@import '../src/components/Button/Button';
@import '../src/components/Dropdown/Dropdown';
@import '../src/components/Dashboard/Dashboard';
@import '../src/components/Filter/Filter';
@import '../src/components/FilterRow/FilterRow';
@import '../src/components/IconLink/IconLink';
@import '../src/components/Checkbox/Checkbox';
@import '../src/components/Chart/Chart';
@import '../src/components/Widget/Widget';
@import '../src/components/ErrorMessage/ErrorMessage';
@import '../src/components/Info/Info';
@import '../src/components/W2/W2';
@import '../src/components/W5/W5';
@import '../src/components/W10/W10';
