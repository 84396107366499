#W5 {
  .amcharts-Label foreignObject {
    overflow: visible;
  }
  .chart-label {
    .amount {
      font-size: 30px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: -0.19px;
      border-bottom: 1px solid;
      padding-bottom: 2px;
      margin-bottom: 3px;
      &-prefix {
        color: $brown-grey;
        font-size: 15px;
        font-weight: 300;
        letter-spacing: -0.11px;
        margin-left: -3px;
      }
    }
    .numbers {
      font-size: 15px;
      font-weight: 300;
      line-height: 1.09;
      letter-spacing: -0.05px;
    }
  }
}
