.Dropdown-root {
  margin-bottom: -10px;
  .Dropdown {
    &-control {
      border: none;
      display: inline-block;
      background-color: transparent;
      padding: 0 22px 0 0;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.03px;
      color: $anthrazit;
      &:hover {
        cursor: pointer;
        color: $cerulean;
        box-shadow: none;
      }
    }
    &-arrow {
      border-color: $cerulean transparent transparent;
      border-width: 4px 4px 0;
      top: 8px;
      margin-top: 0;
    }
    &-menu {
      width: auto;
      min-width: 166px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
      border: none;
      margin-top: -7px;
      padding: 8px;
    }
    &-noresults {
      color: $brown-grey;
      padding: 0;
      text-align: center;
      font-size: 14px;
    }
    &-option {
      position: relative;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.03px;
      color: $anthrazit;
      padding: 4px 36px 3px 8px;
      border-bottom: 1px solid $very-light-pink-two;
      &:last-child {
        border-bottom: none;
        border-radius: 0;
      }
      &:hover {
        background-color: rgba(216, 216, 216, 0.4);
      }
      &.is-selected {
        background-color: $white;
        font-weight: 600;
        color: $cerulean;
        &:before {
          content: '';
          position: absolute;
          width: 11px;
          height: 10px;
          right: 10px;
          top: 10px;
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23007CC8' stroke-width='1.4' d='M134 74.808L138.738 78 145 68' transform='translate(-133 -67)'/%3E%3C/svg%3E%0A");
          background-size: 100% auto;
        }
      }
    }
  }
  &.is-open {
    .Dropdown {
      &-control {
        color: $cerulean;
      }
      &-arrow {
        border-color: $cerulean transparent transparent;
        border-width: 4px 4px 0;
      }
    }
  }
}
