.checkbox-item {
  display: inline-block;
  cursor: pointer;
  user-select: none;
  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ span:after {
      display: block;
    }
  }
  span {
    position: relative;
    padding-left: 21px;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.06px;
    color: $anthrazit;
    &:before {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      width: 13px;
      height: 13px;
      border: solid 1px $cerulean;
      box-sizing: border-box;
    }
    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 2px;
      left: 4px;
      width: 4px;
      height: 8px;
      transform: rotate(34deg);
      border: solid $cerulean;
      border-width: 0 1px 1px 0;
    }
  }
  &:hover {
    span {
      &:before,
      &:after {
        border-color: $prussian-blue;
      }
    }
  }
}
