.icon-link {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.07px;
  color: $anthrazit;
  cursor: pointer;
  span {
    width: 14px;
    height: 14px;
    margin-right: 6px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    &.download {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(1 1)'%3E%3Crect width='12' height='12' fill='%23FFF' stroke='%23007CC8' rx='6'/%3E%3Cg%3E%3Cpath stroke='%23007CC8' d='M0 7L3 4 6 7' transform='translate(3 2) rotate(-180 3 5.5)'/%3E%3Cpath fill='%23007CC8' d='M2.5 0H3.5V7H2.5z' transform='translate(3 2)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }
  &:hover {
    span {
      &.download {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(1 1)'%3E%3Crect width='12' height='12' fill='%23FFF' stroke='%23004C7B' rx='6'/%3E%3Cpath stroke='%23004C7B' d='M3 9L6 6 9 9' transform='rotate(-180 6 7.5)'/%3E%3Cpath fill='%23004C7B' d='M5.5 2H6.5V9H5.5z'/%3E%3C/g%3E%3C/svg%3E");
      }
    }
  }
}
