.react-dropdown-tree-select {
  position: relative;
  z-index: 2;
  &-wrap {
    position: relative;
    user-select: none;
  }
  &-title {
    padding-left: 6px;
    width: 180px;
    font-size: 16px;
    line-height: 1.43;
    letter-spacing: -0.08px;
    color: $anthrazit;
    font-weight: 300;
    cursor: pointer;
    border-bottom: 1px solid $cerulean;
    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-color: $cerulean transparent transparent;
      border-width: 8px 6px 0;
      right: 4px;
      top: 8px;
    }
    &.displayed:before {
      content: '';
      position: fixed;
      cursor: default;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .dropdown {
    position: absolute;
    top: 0;
    right: 0;
    width: 186px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    background-color: $white;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .root {
      padding: 0 8px;
      li {
        border-top: 1px solid #dddddd;
        font-size: 14px;
        font-weight: 300;
        line-height: 2.07;
        letter-spacing: -0.07px;
        color: $brown-grey;
        label {
          position: relative;
          display: block;
          cursor: pointer;
          &:before {
            content: '';
            position: absolute;
            top: 8px;
            left: 7px;
            width: 13px;
            height: 13px;
            border: solid 1px $cerulean;
            background-color: $white;
            box-sizing: border-box;
          }
          &:after {
            content: '';
            position: absolute;
            display: none;
            left: 9px;
            top: 10px;
            width: 9px;
            height: 8px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23007CC8' stroke-width='1.4' d='M134 74.808L138.738 78 145 68' transform='translate(-133 -67)'/%3E%3C/svg%3E%0A");
            background-size: 100% auto;
          }
          input[type='checkbox'] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
        }
        &.checked {
          background: $white;
          font-weight: 600;
          color: $cerulean;
          label:after {
            display: block;
          }
        }
        &.partial label:after {
          display: block;
          left: 10px;
          top: 14px;
          width: 7px;
          height: 1px;
          background: $cerulean;
        }
        &:hover {
          background: rgba(216, 216, 216, 0.4);
        }
        &.tree {
          padding-left: 0 !important;
          label {
            padding-left: 28px;
          }
        }
        &.leaf {
          padding-left: 0 !important;
          &[aria-level='1'] {
            label {
              padding-left: 28px;
              &:before {
                left: 7px;
              }
              &:after {
                left: 9px;
              }
            }
          }
          &[aria-level='2'] {
            label {
              padding-left: 46px;
              &:before {
                left: 28px;
              }
              &:after {
                left: 30px;
              }
            }
          }
        }
      }
    }
    .no-matches,
    .node.leaf.no-item,
    .tag-item:not(:last-child) {
      display: none;
    }
    .tag-item {
      position: relative;
      input[type='text'] {
        width: 100%;
        height: 34px;
        padding: 0 35px 0 12px;
        font-size: 14px;
        font-weight: 300;
        line-height: 2.07;
        letter-spacing: -0.07px;
        color: $anthrazit;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23007CC8' stroke-width='1.154'%3E%3Ccircle cx='5.769' cy='5.769' r='5.192'/%3E%3Cpath d='M9.231 9.231L14.423 14.423'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 10px top 11px;
        border: 0;
        outline: none;
        box-sizing: border-box;
        &::placeholder {
          color: rgba(142, 142, 142, 0.4);
        }
      }
    }
  }
}
