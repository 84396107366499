#W2 {
  .amcharts-Label foreignObject {
    overflow: visible;
  }
  .no-consumption-label {
    .amount {
      font-size: 30px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: -0.19px;
    }
    .numbers {
      font-size: 12px;
      font-weight: 300;
      line-height: 1.09;
      letter-spacing: -0.05px;
    }
  }
}
