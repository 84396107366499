.dashboard {
  .filter-row {
    position: relative;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 11px;
    background-color: $athens-gray;
    z-index: 1;
  }
  .dropdown-wrapper {
    display: flex;
    align-items: center;
    & > label {
      margin-right: 10px;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.03px;
      color: $brown-grey;
    }
    .Dropdown {
      &-control {
        font-size: 16px;
        color: $anthrazit;
        border-radius: 0;
        border-bottom: 1px solid $cerulean;
        line-height: 1.43;
        padding: 0 28px 0 0;
      }
      &-arrow {
        border-color: $cerulean transparent transparent;
        border-width: 8px 6px 0;
        right: 2px;
        top: 8px;
      }
    }
  }
}
