.Dropdown-option, .Dropdown-placeholder {
  &.telekom, &.vodafone {
    padding-left: 24px;

    &::after {
      position: absolute;
      left: 0;
      width: 24px;
      height: 24px;
      content: '';
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.telekom::after {
    // TODO: better fix for image url
    background-image: url('../../../public/logos/telekom.png');
  }

  &.vodafone::after {
    // TODO: better fix for image url
    background-image: url('../../../public/logos/vodafone.png');
  }
}
