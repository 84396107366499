.error {
  &-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
  }
  &-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 372px;
    padding: 24px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    box-sizing: border-box;
    user-select: none;
  }
  &-icon {
    width: 32px;
    height: 28px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='28' viewBox='0 0 32 28'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23007CC8' fill-rule='nonzero' d='M16.51 19.472l.198-9.378h-1.404l.198 9.378h1.008zM16.87 23v-1.62h-1.674V23h1.674z' transform='translate(0 -1)'/%3E%3Cpath stroke='%23007CC8' d='M17.736 3.039L30.29 25.008c.548.959.215 2.18-.744 2.728-.302.173-.644.264-.992.264H3.446c-1.104 0-2-.895-2-2 0-.348.091-.69.264-.992l12.554-21.97c.548-.958 1.77-1.291 2.728-.743.31.177.567.434.744.744z' transform='translate(0 -1)'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
  &-close {
    margin-top: 20px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
 }
  &-message {
    width: 276px;
  }
  &-title {
    font-size: 21px;
    line-height: 1.43;
    letter-spacing: -0.1px;
    color: $slate;
    margin-bottom: 8px;
  }
  &-text {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.04px;
    color: $anthrazit;
    a {
      color: $cerulean;
      text-decoration: none;
    }
    & + & {
      padding-top: 10px;
    }
  }
}
