#W10 {
  .amcharts-Label foreignObject {
    overflow: visible;
  }
  .chart-label {
    .amount {
      font-size: 30px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: -0.19px;
    }
    .numbers {
      font-size: 12px;
      font-weight: 300;
      line-height: 1.88;
      letter-spacing: -0.05px;
      color: $brown-grey;
    }
  }
}
